import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core'
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import FadeIn from "./FadeIn"

import '../assets/styles/components/projectsLink.scss';

const useStyles = makeStyles({
  card: {
    backgroundColor: 'transparent'
  },
  cardContent: {
    padding: 0,
    paddingBottom: '0 !important'
  }
})

const ProjectsLink = ({ slug, title, image, categories, light = false }) => {
  const classes = useStyles();

  return (
    <FadeIn>
      <Card elevation={0} classes={{root: classes.card}} className='projects-link-card'>
        <CardContent classes={{root: classes.cardContent}}>
          <Link to={slug} className='projects-link'>


            <Img fluid={image.childImageSharp.fluid} className='projects-link-image' />
            <div className='projects-link-image-overlay'>
              <div className='overlay' />
              <Typography variant='body2' color='textSecondary'>Learn more</Typography>
            </div>

            <div className={`projects-link-information${light ? ' is-light' : ''}`}>
              <Typography variant='h4' color={ light ? 'textSecondary' : 'textPrimary' }>{title}</Typography>
            </div>
          </Link>
        </CardContent>
      </Card>
    </FadeIn>
  )
}

export default ProjectsLink;
