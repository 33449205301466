import React from 'react';
import Layout from "../components/layout"
import ProjectsLink from "../components/projectsLink"
import { createContent } from "../common/Theme"
import {
  Container,
  Grid
} from '@material-ui/core';
import { graphql } from 'gatsby';
import ContactUs from "../components/contactUs"

import '../assets/styles/pages/projects.scss'

const ProjectsPage = ({ data: { allMarkdownRemark: { edges } } }) => {
  const content = createContent();

  return (
    <Layout>
      <section id='projects-section'>
        <Container classes={{root: content.content}}>

          <Grid container spacing={6} className='projects-grid'>
            {
              edges.map(edge => (
                <Grid item md={6} sm={12} key={edge.node.id}>
                  <ProjectsLink
                    slug={edge.node.frontmatter.slug}
                    title={edge.node.frontmatter.title}
                    image={edge.node.frontmatter.featuredImage}
                    categories={edge.node.frontmatter.categories}
                  />
                </Grid>
              ))
            }
          </Grid>
        </Container>
      </section>

      <ContactUs />
    </Layout>
  )
}

export default ProjectsPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { postType: { eq: "case" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            slug
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
