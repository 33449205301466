import React, { useEffect, useRef } from 'react';

import '../assets/styles/components/fadeIn.scss'

const FadeIn = (props) => {
  const ref = useRef(null);

  const callback = ([entry]) => {
    if (entry.boundingClientRect.y <= 0) return;

    if (entry.isIntersecting) {
      entry.target.classList.add('animate');
    } else {
      entry.target.classList.remove('animate');
    }
  }

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0,
    }

    const observer = new IntersectionObserver(callback, options);

    const current = ref.current;

    if (current) observer.observe(current);

    return () => {
      if (current) observer.unobserve(current);
    }
  }, [ref]);

  return (
    <div className='fade-in' ref={ref}>
      {props.children}
    </div>
  )
}

export default FadeIn;
